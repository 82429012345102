import './App.css';
import Privacy from "./Privacy";

function App() {
    return (
        <>
        <div className="App container">
            <header className="App-header">
                <div className="loading">
                    <div className="stelar center">&nbsp;</div>
                    <div className="moon center">&nbsp;</div>
                </div>
                <p>
                    This game should be a tribute to the old era before DLCs and Ads in game was a thing.
                    A simple game that tries to put the focus in FUN not money!
                    When you have ideas to add to this game give us a message.
                </p>
                <a href="#" title="Not available yet">Google Playstore</a>
                <a href="https://www.facebook.com/WorldOfKemomi/">Kemomi on Facebook</a>
                <a href="#privacy">Datenschutz</a>
            </header>

        </div>
        <Privacy />
        </>
    );
}

export default App;
